import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import Cookies from 'universal-cookie';

import NotFound from '/app/src/NotFound.js';
import Main from '/app/src/Main.js';
import Settings from '/app/src/Settings.js';

const cookies = new Cookies();

class App extends Component {

  constructor() {
    super();

    this.state = {
    }
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>

          <Route exact path="/404" component={NotFound} />
          <Route exact path="/settings" component={Settings} />

          <Route exact path="/" component={Main} />
          <Route path="/:project_id" component={Main} />

        </Switch>
      </BrowserRouter>
    );
  }

}

export default App;
