import React, { Component } from 'react';
import Cookies from 'universal-cookie';

import Header from '/app/src/Header.js';
import Footer from '/app/src/Footer.js';

import brace from "brace";
import AceEditor from "react-ace";

import "brace/mode/perl";
import "brace/theme/github";

var myCode = require('/app/src/isValidFileName.js')

class App extends Component {

  constructor() {
    super();

    this.state = {
      command: '',

      files: [],
      currentFileNumber: 0,

      // some global error, project wasn't created, task wasn't created.
      // this error means something is wrong with the system
      error: '',

      /*
        key - kit_id
        value - string with error

        Errors that belongs to running code. User can input some code that creates such errors.
      */
      errors: {},

      /*
        key - kit_id
        value - result for that kit_id

        When there is no result, there is no key.
      */
      results: {},

      initComplete: false,
      isEditingFileName: false,
      projectHasChanged: true,

      project_id: null,

      /*
        key - kit_id
        value - task_id

        Stores only running tasks. After the task is complete (success, or fail) the value is removed from this object.
        So the empty object means there is no runnig tasks.
      */
      task_ids: {},

      intervalId: null,
      isLoading: false,
      needToShowCodeIsSavedText: false,
      autoFocusCode: true,

      kits:
/* KITS START */
[
    {
        "kit_id" : "perl-5.8",
        "label" : "5.8",
        "perl_version" : "5.8.9"
    },
    {
        "kit_id" : "perl-5.10",
        "label" : "5.10",
        "perl_version" : "5.10.1"
    },
    {
        "kit_id" : "perl-5.12",
        "label" : "5.12",
        "perl_version" : "5.12.5"
    },
    {
        "kit_id" : "perl-5.14",
        "label" : "5.14",
        "perl_version" : "5.14.4"
    },
    {
        "kit_id" : "perl-5.16",
        "label" : "5.16",
        "perl_version" : "5.16.3"
    },
    {
        "kit_id" : "perl-5.18",
        "label" : "5.18",
        "perl_version" : "5.18.4"
    },
    {
        "kit_id" : "perl-5.20",
        "label" : "5.20",
        "perl_version" : "5.20.3"
    },
    {
        "kit_id" : "perl-5.22",
        "label" : "5.22",
        "perl_version" : "5.22.4"
    },
    {
        "kit_id" : "perl-5.24",
        "label" : "5.24",
        "perl_version" : "5.24.4"
    },
    {
        "kit_id" : "perl-5.26",
        "label" : "5.26",
        "perl_version" : "5.26.3"
    },
    {
        "kit_id" : "perl-5.28",
        "label" : "5.28",
        "perl_version" : "5.28.3"
    },
    {
        "kit_id" : "perl-5.30",
        "label" : "5.30",
        "perl_version" : "5.30.3"
    },
    {
        "kit_id" : "perl-5.32",
        "label" : "5.32",
        "perl_version" : "5.32.1"
    },
    {
        "kit_id" : "perl-5.34",
        "label" : "5.34",
        "perl_version" : "5.34.3"
    },
    {
        "kit_id" : "perl-5.36",
        "label" : "5.36",
        "perl_version" : "5.36.3"
    },
    {
        "kit_id" : "perl-5.38",
        "label" : "5.38",
        "perl_version" : "5.38.2"
    },
    {
        "kit_id" : "perl-5.40",
        "label" : "5.40",
        "perl_version" : "5.40.0"
    }
],
/* KITS END */

      selectedKits: {},
    }

    this.isValidFileName = this.isValidFileName.bind(this);

    this.handleKeyboard = this.handleKeyboard.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleKitCheckboxChange = this.handleKitCheckboxChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCookieWarningClose = this.handleCookieWarningClose.bind(this);

    this.handleFileAdd = this.handleFileAdd.bind(this);
    this.handleFileClose = this.handleFileClose.bind(this);
    this.handleFileNameClick = this.handleFileNameClick.bind(this);
    this.handleFileNameChange = this.handleFileNameChange.bind(this);
    this.closeFileNameEdit = this.closeFileNameEdit.bind(this);
    this.handleAllClick= this.handleAllClick.bind(this);
    this.handleNoneClick= this.handleNoneClick.bind(this);

    this.terminateProject = this.terminateProject.bind(this);

    this.onChangeAce = this.onChangeAce.bind(this);
  }

  handleChange(event) {

    this.terminateProject();

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    var newState = {
      needToShowCodeIsSavedText: true,
    };

    if (name === 'code') {
      var files = this.state.files;
      files[this.state.currentFileNumber].fileContent = value;
      newState.files = files;
    } else {
      newState[name] = value;
    }

    this.setState(newState);
  }

  onChangeAce(newValue) {
    this.handleChange({
      target: {
        value: newValue,
        name: 'code',
      }
    });
  }

  handleKitCheckboxChange(event) {

    this.terminateProject();

    const target = event.target;
    const value = target.checked;
    const name = target.name;

    var newState = this.state.selectedKits;
    newState[name] = value;

    this.setState({
      selectedKits: newState
    });

  }

  handleSubmit(event) {

    if (event !== undefined) {
      event.preventDefault();
    }

    var _this = this;

    this.setState({
      isLoading: true,
      results: {},
      error: '',
      errors: {},
      isEditingFileName: false,
      projectHasChanged: false,
    }, function() {
      if (_this.state.project_id === null || _this.state.projectHasChanged) {
        var tmpFiles = [];
        _this.state.files.forEach(function(file){
          tmpFiles.push({
            file_name: file.fileName,
            content: file.fileContent,
          });
        });

        var kits = {};
        Object.keys(_this.state.selectedKits).forEach(function(kit_id) {
          if (_this.state.selectedKits[kit_id]) {
            kits[kit_id] = {};
          }
        });

        var project = {
          command: _this.state.command,
          files: tmpFiles,
          kits: kits,
        };

        _this.createProject(project, function(project_id) {
          _this.props.history.push('/' + project_id);
          _this.createTask({ project_id: project_id });
        });
      } else {
        _this.createTask({ project_id: _this.state.project_id });
      }
    });

  }

  handleKeyboard(e) {

    if (!document.getElementById('theButton').disabled && e.keyCode===13 && (e.ctrlKey || e.metaKey)) {
      this.handleSubmit();
    };

    // esc
    if (e.keyCode === 27) {
      this.setState({
        isEditingFileName: false,
      });
    }

    if (e.key === 'Enter') {
      e.stopPropagation()
      this.setState({
        isEditingFileName: false,
      });
    }

  }

  componentDidMount() {

    document.addEventListener('keydown', this.handleKeyboard);

    var _this = this;

    if (this.props.match.params.project_id === undefined) {
      // new page

      var fileContent = "#!/usr/bin/perl\n\nuse Data::Dumper;\n\nprint Dumper { a => 1 };";

      const cookies = new Cookies();
      var defaultCode = cookies.get('default-code');
      if (defaultCode !== undefined) {
        fileContent = defaultCode;
      }

      this.setState({
        command: 'perl script.pl',
        files: [{
          fileName: 'script.pl',
          fileContent: fileContent,
        }],
        selectedKits: {
          "perl-5.40" : true,
        },
        initComplete: true,
        needToShowCodeIsSavedText: true,
      });

      this.session(function(){});

    } else {
      // need to load project data

      this.setState({
        isLoading: true,
      }, function() {
        _this.session(function(){
          _this.getProject(_this.props.match.params.project_id, function(project_id) {
            _this.createTask({ project_id: project_id });
          });
        });
      });
    }

    var intervalId = setInterval(function() {

      for (var kit_id in _this.state.task_ids) {
        var task_id = _this.state.task_ids[kit_id];

        _this.getTask(task_id, kit_id, function(answer, kit_id) {

          if (answer.status === 'done') {
          // done

            _this.setState(function(prevState, props){

              var tmpTaskIds = prevState.task_ids;
              delete tmpTaskIds[kit_id];

              var tmpResults = prevState.results;
              tmpResults[kit_id] = answer.result;

              var newState = {
                task_ids: tmpTaskIds,
                results: tmpResults,
              };

              if ( Object.keys(tmpTaskIds).length === 0 ) {
                newState.isLoading = false;
              }

              return newState;
            });

          } else if (answer.status === 'fail_timeout') {
          // fail_timeout

            _this.setState(function(prevState, props){

              var tmpTaskIds = prevState.task_ids;
              delete tmpTaskIds[kit_id];

              var tmpErrors = prevState.errors;
              tmpErrors[kit_id] = 'Stopped execution. Code is running too long.';

              var newState = {
                task_ids: tmpTaskIds,
                errors: tmpErrors,
              };

              if ( Object.keys(tmpTaskIds).length === 0 ) {
                newState.isLoading = false;
              }

              return newState;
            });

          } else if (answer.status === 'fail_too_big') {
          // fail_too_big

            _this.setState(function(prevState, props){

              var tmpTaskIds = prevState.task_ids;
              delete tmpTaskIds[kit_id];

              var tmpResults = prevState.results;
              tmpResults[kit_id] = answer.result;

              var tmpErrors = prevState.errors;
              tmpErrors[kit_id] = 'The result it too big. Showing only the beginning.';

              var newState = {
                task_ids: tmpTaskIds,
                results: tmpResults,
                errors: tmpErrors,
              };

              if ( Object.keys(tmpTaskIds).length === 0 ) {
                newState.isLoading = false;
              }

              return newState;
            });

          }
        });
      }
    }, 500);

    this.setState({intervalId: intervalId});
  }

  componentWillUnmount() {
   document.removeEventListener('keydown', this.handleKeyboard);
   clearInterval(this.state.intervalId);
  }

  handleCookieWarningClose() {

    const cookies = new Cookies();
    cookies.set('cookie-warning-is-closed', '1', { path: '/', expires: new Date(Date.now()+365000000000) });

    fetch("/api/internal/close-cookie-warning", {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "GET",
      credentials: 'include',
    });

    this.setState({tmp: 1});
  }

  getProject(project_id, after) {
    var _this = this;

    fetch("/api/internal/project/" + _this.props.match.params.project_id, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "GET",
      credentials: 'include',
    })
    .then(function(response) {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    })
    .then(function(data) {

      var files = [];
      data.files.forEach(function(file){
        files.push({
          fileName: file.file_name,
          fileContent: file.content,
        })
      });

      var tmpSelectedKits = {};
      for (var kit_id in data.kits) {
        tmpSelectedKits[kit_id] = true;
      }

      _this.setState({
        project_id: project_id,
        files: files,
        command: data.command,
        selectedKits: tmpSelectedKits,
        initComplete: true,
        projectHasChanged: false,
      }, function() {
        after(project_id);
      });
    }).catch(function(error) {
      if (error.status === 404) {
        _this.props.history.push('/404');
      }
    });
  }

  createProject(project, after) {
    var _this = this;
    fetch("/api/internal/project", {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(project),
      credentials: 'include',
    })
    .then(function(response) {
      if (!response.ok) {
        throw Error(response.statusText);
      }

      return response.json();
    })
    .then(function(data) {

      if (data.project_id === undefined) {
        throw Error('No project_id');
      }

      _this.setState({
        project_id: data.project_id,
      }, function() {
        after(data.project_id);
      });
    })
    .catch(function(error) {
      _this.setState({
        isLoading: false,
        error: 'An Error occurred, please refresh the page and try again.',
      });
    });
  }

  getTask(task_id, kit_id, after) {
    fetch("/api/internal/task/" + task_id, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "GET",
      credentials: 'include',
    })
    .then(function(response) {
      return response.json();
    })
    .then(function(data) {
      after(data, kit_id);
    });
  }

  createTask(task) {
    var _this = this;
    fetch("/api/internal/tasks", {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(task),
      credentials: 'include',
    })
    .then(function(response) {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(function(data) {
      _this.setState({
        task_ids: data,
      });
    })
    .catch(function(error) {
      _this.setState({
        isLoading: false,
        error: 'An Error occurred, please refresh the page and try again.',
      });
    });
  }

  session(after) {
    fetch("/api/internal/session", {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      credentials: 'include',
    })
    .then(function(response) {
      return response.json();
    })
    .then(function(data) {
      after(data.output);
    });
  }

  handleFileAdd() {
    this.terminateProject();

    var files = this.state.files;

    var tmpObj = {};
    files.forEach(function(file){
      tmpObj[file.fileName] = 1;
    });

    var newFileName = '';

    if (tmpObj['lib/Foo.pm'] === undefined) {
      newFileName = 'lib/Foo.pm';
    } else if (tmpObj['lib/Bar.pm'] === undefined) {
      newFileName = 'lib/Bar.pm';
    } else if (tmpObj['lib/Baz.pm'] === undefined) {
      newFileName = 'lib/Baz.pm';
    } else if (tmpObj['lib/Qux.pm'] === undefined) {
      newFileName = 'lib/Qux.pm';
    } else if (tmpObj['lib/Quux.pm'] === undefined) {
      newFileName = 'lib/Quux.pm';
    }

    files.push({
      fileName: newFileName,
      fileContent: '',
    });

    this.setState({
      files: files,
      currentFileNumber: files.length - 1,
      isEditingFileName: false,
    });
  }

  terminateProject() {

    if (this.props.match.params.project_id !== undefined) {
      this.props.history.push('/');
    }

    this.setState({
      projectHasChanged: true,
      project_id: null,
      results: {},
      error: '',
      errors: {},
    });

  }

  handleFileClose(event) {

    this.terminateProject();

    // to disable firing handleFileNameClick()
    event.stopPropagation()

    var files = this.state.files;
    files.splice(Number(event.currentTarget.dataset.name), 1);

    var currentFileNumber = this.state.currentFileNumber;

    if (currentFileNumber > files.length - 1) {
      currentFileNumber = files.length - 1;
    }

    this.setState({
      currentFileNumber: currentFileNumber,
      files: files,
      isEditingFileName: false,
      projectHasChanged: true,
      autoFocusCode: false,
    });
  }

  handleFileNameClick(event) {
    if (this.state.currentFileNumber === Number(event.currentTarget.dataset.name)) {
      if (!this.state.isLoading) {
        this.setState({
          isEditingFileName: true,
        });
      }
    } else {
      this.setState({
        currentFileNumber : Number(event.currentTarget.dataset.name),
        isEditingFileName: false,
      });
    }
  }

  handleFileNameChange(event) {

    this.terminateProject();

    var files = this.state.files;
    var currentFileNumber = this.state.currentFileNumber;

    files[currentFileNumber].fileName = event.currentTarget.value;

    this.setState({
      files: files,
    });
  }

  closeFileNameEdit() {
    this.setState({
      isEditingFileName: false,
    });
  }

  isValidFileName(fileName) {
    return myCode.isValidFileName(fileName);
  }

  handleAllClick(event) {
    this.terminateProject();

    var newState = {};
    this.state.kits.forEach(function(kit) {
      newState[kit.kit_id] = true;
    });

    this.setState({
      selectedKits: newState
    });
  }

  handleNoneClick(event) {
    this.terminateProject();

    this.setState({
      selectedKits: {}
    });
  }

  render() {

    var _this = this;

    var buttonClassName;
    var buttonIsActive;
    var buttonText = 'Save for 30 days & run';

    if (this.state.isLoading) {
      buttonClassName = 'button is-large is-primary is-fullwidth is-loading';
      buttonIsActive = false;
    } else {
      buttonClassName = 'button is-large is-primary is-fullwidth';
      buttonIsActive = true;

      if (!this.state.projectHasChanged) {
        buttonText = 'Run again';
      }
    }

    var message = [];

    var url = window.location.href;

    if (url.startsWith('https://perlbanjo.com/')) {
      url = url.replace('https://perlbanjo.com/', 'https://PerlBanjo.com/');
    }

    if (this.state.needToShowCodeIsSavedText && !this.state.projectHasChanged && this.state.project_id !== null) {
      message.push(<span key="message">Code is saved as <a href={ url }>{ url }</a></span>);
    }

    var error = [];

    if (this.state.error.length > 0) {
      var addition = '';

      if (message.length > 0) {
        addition = ' ';
      }

      error.push(<span key="error" style={{color: 'red'}}>{ addition }{ this.state.error }</span>);
    }

    var commandTooltipText = '';
    var commandTooltipClass = 'column';

    if (this.state.initComplete && this.state.command.length === 0) {
      buttonIsActive = false;
      commandTooltipText = 'You need to specify command';
      commandTooltipClass = 'column tooltip is-tooltip-danger is-tooltip-active';
    }

    const cookies = new Cookies();
    var cookiesBlock = [];
    if (cookies.get('cookie-warning-is-closed') === undefined) {
      cookiesBlock.push(<div className="column" key="cookieWarning" data-nosnippet="">
        <article className="message is-warning">
          <div className="message-header">
            <span>
              <span role="img" aria-label="cookie">🍪</span>&nbsp;Cookie warning
            </span>
            <a className="delete" onClick={this.handleCookieWarningClose}></a>
          </div>
          <div className="message-body" style={{backgroundColor: '#fffdc5'}}>
            By using this site, you agree that this site may use cookies,
            web beacons, tracking pixels, and other tracking technologies
            to identify sessions, customize the site and to do other things to make this site work.
            More details are avaliable in <a href="/cookie-policy">Cookie Policy</a>.
          </div>
        </article>
      </div>);
    };

    var tabs = [];

    var fileNamesObj = {};
    this.state.files.forEach(function(file){
      if (isNaN(fileNamesObj[file.fileName])) {
        fileNamesObj[file.fileName] = 1;
      } else {
        fileNamesObj[file.fileName]++;
      }
    });

    var fileNumber = 0;
    this.state.files.forEach(function(file){
      var tabClassName='';

      if (fileNumber === _this.state.currentFileNumber) {
        tabClassName='is-active';
      }

      var fileNameOrInput = [];

      if(_this.state.isEditingFileName && (fileNumber === _this.state.currentFileNumber)) {
        fileNameOrInput.push(<span key={ 'span_' + fileNumber }>
          <input
            style={{fontSize: '12pt'}}
            onChange={_this.handleFileNameChange}
            value={file.fileName}
            autoFocus
            ></input>
        </span>);
      } else {
        var crossStyle = {};
        var closeOnClick;

        if (_this.state.isLoading) {
          crossStyle.color = '#c1c1c1';
        } else {
          closeOnClick = _this.handleFileClose;
        }

        fileNameOrInput.push(<span key={ 'file_' + fileNumber }>
          <span style={{ paddingLeft: '0.15rem' }}>{file.fileName}</span>
          <span style={ crossStyle } className="fas fa-times pbClose" onClick={ closeOnClick } data-name={ fileNumber }></span>
        </span>
        );
      }

      var aClassName = '';

      if (/\/$/.test(file.fileName)) {
        file.fileName = file.fileName.slice(0, -1);
      }

      if (!_this.isValidFileName(file.fileName) || fileNamesObj[file.fileName] > 1) {
        aClassName = 'invalid-file-name';
        buttonIsActive = false;
      }

      if (fileNumber === _this.state.currentFileNumber && _this.state.isLoading) {
        aClassName = aClassName + ' unsetCursor';
      }

      tabs.push(<li className={tabClassName} key={ 'li_' + fileNumber }>
        <a onClick={_this.handleFileNameClick} data-name={ fileNumber } className={ aClassName }>
          {fileNameOrInput}
        </a>
      </li>);
      fileNumber++;
    });

    if (this.state.files.length < 5) {

      var plusStyle = {
        padding: '0.2rem',
      };

      var pbAddOnClick;
      var pbAddClassName = '';
      var pbAddStyle = {};

      if (this.state.isLoading) {
        plusStyle.color = '#c1c1c1';
        pbAddClassName = 'pbAddDisabled';
        pbAddStyle = {
          backgroundColor: 'white',
          borderBottomColor: '#dbdbdb',
        };
      } else {
        pbAddOnClick = this.handleFileAdd;
        pbAddClassName = 'pbAdd';
      }

      tabs.push(<li key="pbAdd">
        <a onClick={ pbAddOnClick } className={ pbAddClassName } style={pbAddStyle}>
          &nbsp;
          <span style={ plusStyle } className="fas fa-plus"></span>
          &nbsp;
        </a>
      </li>);
    }

    var editor = [];

    if (this.state.files.length > 0) {

      if (cookies.get('editor-type') === 'textarea') {

      editor.push(<textarea
        key="textarea_code"
        className="textarea"
        rows="18"
        name="code"
        maxLength="5120"
        value={this.state.files[this.state.currentFileNumber].fileContent}
        onChange={this.handleChange}
        onFocus={this.closeFileNameEdit}
        style={{fontFamily: 'Menlo-Regular, Monaco, monospace'}}
        autoFocus={this.state.autoFocusCode}
        readOnly={this.state.isLoading}
      ></textarea>);

      } else {

      editor.push(<AceEditor
        key="ace_code"
        onChange={this.onChangeAce}
        value={this.state.files[this.state.currentFileNumber].fileContent}
        focus={this.state.autoFocusCode}
        readOnly={this.state.isLoading}
        onFocus={this.closeFileNameEdit}
        onLoad={function(editor){
            editor.renderer.setPadding(10);
            editor.renderer.setScrollMargin(10);

            // mouseup = css resize end
            document.addEventListener("mouseup", e => (
              editor.resize()
            ));
        }}

        mode="perl"
        theme="github"

        name="editor"
        width="100%"
        height="497px"
        fontSize={16}
        minLines={18}
        style={{
          borderBottom: '1px solid #dbdbdb',
          borderLeft: '1px solid #dbdbdb',
          borderRight: '1px solid #dbdbdb',
          borderRadius: '0px 0px 4px 4px',
          resize: 'vertical',
        }}

        showGutter={ cookies.get('show-line-numbers') === 'yes' }

        highlightActiveLine={false}
        editorProps={{
          $blockScrolling: Infinity,
        }}
        setOptions={{
          displayIndentGuides: true,
          tabSize: 4,
        }}
        commands={[
          {
            bindKey: {win: 'Ctrl-l', mac: 'Command-l'},
          },
        ]}
      />);
      }
    }

    var kitCheckboxes = [];
    var results = [];
    var selectedKitsCount = 0;

    for (var kit_id in this.state.selectedKits) {
      if (this.state.selectedKits[kit_id]) {
        selectedKitsCount++;
      }
    }

    var resultsTextAreaRows = 10 - selectedKitsCount;
    if (resultsTextAreaRows < 4) {
      resultsTextAreaRows = 4;
    }

    var kitNumber = 1;

    this.state.kits.forEach(function(kit) {

      var kitCheckboxClassName = 'kitCheckbox kitCheckboxEnabled';
      var kitLabelClassName = 'kitLabel kitLabelEnabled';

      if (_this.state.isLoading) {
        kitCheckboxClassName = 'kitCheckbox';
        kitLabelClassName = 'kitLabel';
      }

      kitCheckboxes.push(<span key={ 'span_' + kit.kit_id } style={{ display: 'inline-block', paddingRight: '1.5em', whiteSpace: 'nowrap' }}>
        <label htmlFor={ kit.kit_id} className={ kitLabelClassName }>
            <input
                key={ 'input_checkbox_' + kit.kit_id }
                className={ kitCheckboxClassName }
                type="checkbox"
                name={ kit.kit_id }
                id={ kit.kit_id }
                onChange={ _this.handleKitCheckboxChange }
                disabled={ _this.state.isLoading }
                checked={ _this.state.selectedKits[kit.kit_id] }
            />
            { kit.label }
        </label>
      </span>);

      if (_this.state.selectedKits[kit.kit_id]) {

        var safePerlVersion = kit.perl_version.replace(/\./g, '-');

        var controlClassName = 'control';
        if (_this.state.task_ids[kit.kit_id]) {
          controlClassName = controlClassName + ' is-loading';
        }

        var tmpError = '';
        if (_this.state.errors[kit.kit_id]) {
          tmpError = _this.state.errors[kit.kit_id];
        }

        var tmpValue = '';
        if (_this.state.results[kit.kit_id]) {
          tmpValue = _this.state.results[kit.kit_id];
        }

        var tmpMarginBottom;

        if (kitNumber === selectedKitsCount) {
            tmpMarginBottom = '0';
        } else {
            tmpMarginBottom = '3rem';
        }

        results.push(
          <div key={ 'div1_' + kit.kit_id } className={ "version-badge version-badge-" + safePerlVersion }>
          <div className={ controlClassName }>
          <div className="resultError">{ tmpError }</div>
          <textarea className="textarea"
            rows={ resultsTextAreaRows }
            onFocus={ _this.closeFileNameEdit}
            value={ tmpValue }
            style={{fontFamily: 'Menlo-Regular, Monaco, monospace', borderTopLeftRadius: 0, marginTop: '0.9rem', marginBottom: tmpMarginBottom }}
            readOnly
          >
          </textarea>
          </div>
          </div>
        );

        kitNumber++;
      }
    });

    if (selectedKitsCount === 0) {
      buttonIsActive = false;
    }

    var kitSelectorAll = [];
    var kitSelectorNone = [];

    if (this.state.isLoading || selectedKitsCount === this.state.kits.length) {
      kitSelectorAll.push(
        <span className="kitSelector">all</span>
      );
    } else {
      kitSelectorAll.push(
        <span className="kitSelector kitSelectorEnabled" onClick={this.handleAllClick}>all</span>
      );
    }

    if (this.state.isLoading || selectedKitsCount === 0) {
      kitSelectorNone.push(
        <span className="kitSelector">none</span>
      );
    } else {
      kitSelectorNone.push(
        <span className="kitSelector kitSelectorEnabled" onClick={this.handleNoneClick}>none</span>
      );
    }

    return (
<div>

<Header/>

<section className="section">
  <form onSubmit={this.handleSubmit}>
    <div className="columns">
        <div className="column">

            <div className="tabs is-boxed">
              <ul>
                { tabs }
              </ul>
            </div>

            { editor }

        </div>
        <div className="column">
            <div className="columns is-vcentered">
                <div className="column is-narrow">
                   Command:
                </div>
                <div className={ commandTooltipClass } data-tooltip={ commandTooltipText }>
                    <textarea
                      className="textarea"
                      rows="1"
                      name="command"
                      maxLength="1024"
                      value={this.state.command}
                      onChange={this.handleChange}
                      onFocus={this.closeFileNameEdit}
                      style={{fontFamily: 'Menlo-Regular, Monaco, monospace'}}
                      readOnly={this.state.isLoading}
                    ></textarea>
                </div>
            </div>

            <div style={{ marginBottom: '1em' }}>
            { kitCheckboxes }
            { kitSelectorAll }
            <span className="kitSelectorSeparator">/</span>
            { kitSelectorNone }
            </div>

            <button id="theButton" className={ buttonClassName } disabled={ !buttonIsActive }>{ buttonText }</button>
            <div className="has-text-centered">
            { message }
            { error }
            &nbsp;
            </div>
            <br/>

            { results }

        </div>
    </div>
  </form>
</section>

<section className="section">
    <div className="columns">
        <div className="column">
        </div>
        { cookiesBlock }
    </div>
</section>

<Footer/>

</div>
    );
  }

}

export default App;
