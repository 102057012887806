import React, { Component } from 'react';

import Header from '/app/src/Header.js';
import Footer from '/app/src/Footer.js';

class NotFound extends Component {

  constructor() {
    super();

    this.state = {
    }
  }

  render() {
    return (
      <div>

        <Header/>

        <section className="hero is-primary is-large" style={{minHeight: '30rem'}}>
          <div className="hero-body">
            <div className="container">
              <h1 className="title">
                Page not found
              </h1>
              <h2 className="subtitle">
                Error 404
              </h2>
            </div>
          </div>
        </section>

        <Footer/>

      </div>
    );
  }

}

export default NotFound;
