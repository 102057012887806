import React, { Component } from 'react';

class Header extends Component {

  constructor() {
    super();

    this.state = {
    }
  }

  render() {
    return (
<section className="hero is-light">
<nav className="navbar" role="navigation" aria-label="main navigation">
  <div className="navbar-brand">
    <a className="navbar-item is-size-4" href="/" style={{ padding: '.5rem 1.5rem' }}>
     PerlBanjo.com
    </a>

    <a className="navbar-item" style={{height: '100%', margin: 0, padding: '.5rem 1.5rem', position: 'absolute', right: '0'}} href="/settings">
      <i className="fas fa-cog"></i>
      <span className="is-hidden-mobile">&nbsp;Settings</span>
    </a>
  </div>
</nav>
</section>
    );
  }

}

export default Header;
