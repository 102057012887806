import React, { Component } from 'react';
import Cookies from 'universal-cookie';

import Header from '/app/src/Header.js';
import Footer from '/app/src/Footer.js';

import brace from "brace";
import AceEditor from "react-ace";

import "brace/mode/perl";
import "brace/theme/github";

class Settings extends Component {

  constructor() {
    super();

    this.state = {
      editorType: "Ace",
      showLineNumbers: false,
      defaultCode: '#!/usr/bin/perl\n\nuse Data::Dumper;\n\nprint Dumper { a => 1 };',
    }

    this.handleChange = this.handleChange.bind(this);
    this.onChangeAce = this.onChangeAce.bind(this);
  }

  componentDidMount() {
    const cookies = new Cookies();
    var cookiesBlock = [];
    if (cookies.get('show-line-numbers') === 'yes') {
      this.setState({
        showLineNumbers: true,
      });
    }

    if (cookies.get('editor-type') === 'textarea') {
      this.setState({
        editorType: 'textarea',
      });
    }

    var defaultCode = cookies.get('default-code');
    if (defaultCode !== undefined) {
      this.setState({
        defaultCode: defaultCode,
      });
    }

  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name === 'show-line-numbers') {
      if (value) {
        const cookies = new Cookies();
        cookies.set('show-line-numbers', 'yes', { path: '/', expires: new Date(Date.now()+365000000000) });
        this.setState({
          showLineNumbers: true,
        });
      } else {
        const cookies = new Cookies();
        cookies.set('show-line-numbers', 'no', { path: '/', expires: new Date(Date.now()+365000000000) });
        this.setState({
          showLineNumbers: false,
        });
      }
    } else if (name === 'editor-type') {
      const cookies = new Cookies();
      cookies.set('editor-type', value, { path: '/', expires: new Date(Date.now()+365000000000) });

      this.setState({
        editorType: value,
      });
    } else if (name === 'default-code') {
      const cookies = new Cookies();
      cookies.set('default-code', value, { path: '/', expires: new Date(Date.now()+365000000000) });

      this.setState({
        defaultCode: value,
      });
    }
  }

  onChangeAce(value) {
    const cookies = new Cookies();
    cookies.set('default-code', value, { path: '/', expires: new Date(Date.now()+365000000000) });

    this.setState({
      defaultCode: value,
    });
  }

  render() {
    var editor = [];

    if (this.state.editorType === 'Ace') {

      editor.push(<AceEditor
        key="ace_code"
        onChange={ this.onChangeAce }
        value={ this.state.defaultCode }
        onLoad={function(editor){
            editor.renderer.setPadding(10);
            editor.renderer.setScrollMargin(10);

            // mouseup = css resize end
            document.addEventListener("mouseup", e => (
              editor.resize()
            ));
        }}

        focus={ false }

        mode="perl"
        theme="github"

        name="editor"
        width="100%"
        height="200px"
        fontSize={16}
        minLines={18}
        style={{
          border: '1px solid #dbdbdb',
          borderRadius: '0px 0px 4px 4px',
          resize: 'vertical',
        }}

        showGutter={ this.state.showLineNumbers }

        highlightActiveLine={false}
        editorProps={{
          $blockScrolling: Infinity,
        }}
        setOptions={{
          displayIndentGuides: true,
          tabSize: 4,
        }}
        commands={[
          {
            bindKey: {win: 'Ctrl-l', mac: 'Command-l'},
          },
        ]}
      />);

    } else {
      editor.push(
        <textarea
          className="textarea"
          name="default-code"
          cols="40"
          rows="8"
          onChange={ this.handleChange }
          value={ this.state.defaultCode }
        />
      );
    }

    return (
      <div>

        <Header/>
        <style>
      </style>

        <section className="section" style={{paddingBottom: '10rem'}}>

          <h1 className="title">Settings</h1>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Editor</label>
            </div>
            <div className="field-body">
              <div className="control">
                <div className="select">
                  <select
                    name="editor-type"
                    onChange={ this.handleChange }
                  >
                    <option selected={ this.state.editorType === 'Ace' }>Ace</option>
                    <option selected={ this.state.editorType === 'textarea' }>textarea</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
            </div>
            <div className="field-body">
              <label className="checkbox" style={ this.state.editorType === 'textarea' ? { cursor: 'default' } : {} }>
                <input
                  type="checkbox"
                  name="show-line-numbers"
                  checked={ this.state.editorType === 'Ace' && this.state.showLineNumbers }
                  onChange={ this.handleChange }
                  disabled={ this.state.editorType !== 'Ace' }
                  style={ this.state.editorType === 'textarea' ? { cursor: 'default' } : {} }
                />
                <span className="noselect" style={ this.state.editorType === 'textarea' ? { color: 'silver' } : {}}>&nbsp;Show line numbers in the Ace editor</span>
              </label>
            </div>
          </div>

          <br/>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Default code</label>
            </div>
            <div className="field-body">
              <div className="control">

                <div className="field">
                  <div className="control">
                    { editor }
                    <span style={{color: 'silver'}}>This is the code that is shown when you open <a href="/" style={{ color: 'silver', textDecoration: 'underline'}}>PerlBanjo main page</a>.</span>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </section>


        <Footer/>

      </div>
    );
  }

}

export default Settings;
